import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/layout'
import Breadcrumb from "../components/Global/Breadcrumb"
import "../css/Events.css";
import Calendar from "./../images/calendar.svg"
import { el } from 'date-fns/locale'

// import RelatedPosts from '../components/RelatedPosts'
import htmlDecode from "html-entities-decoder";
import { format } from 'date-fns'

function EventsTemplate(props) {
    console.log(props)
    let data = props.data;

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>

                    <section>
                        <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>

                    <section>
                        <div className="container-cs m-auto py-16">
                            <div className="w-full flex flex-wrap">

                                {(data.allWpPage.nodes[0].relatedEvents.relatedEvents && data.allWpPage.nodes[0].relatedEvents.relatedEvents.length > 0) && data.allWpPage.nodes[0].relatedEvents.relatedEvents.map((post, i) => {
                                    return (
                                        <article className="w-full md:w-1/2 lg:w-1/3 p-4" key={`post-tab-${i}`}>
                                            <Link className="flex pb-4 justify-center" style={{ boxShadow: "0px 0px 50px rgb(5 5 5 / 8%)" }} to={`${props.pageContext.language === "EL" ? "/blog/" : "/" + props.pageContext.language.toLowerCase() + "/blog/"}${post.slug}/`}>
                                                <div className="relative bp-article">
                                                    <div className="blog-f-img">
                                                        {post.featuredImage && (
                                                            <img
                                                                src={post.featuredImage.node.sourceUrl}
                                                                alt={post.featuredImage.node.altText}
                                                                className="h-full w-full shadow"
                                                                loading="lazy" />

                                                        )}
                                                    </div>

                                                    <div className=" px-4 md:px-6 pt-4">
                                                        <h2 className="entry-title">{htmlDecode(post.title)}</h2>
                                                    </div>

                                                    <div className="w-full z-10 pb-4">
                                                        <div className="flex px-4 md:px-6 blog-info">
                                                            <div className="flex justify-center items-center">
                                                                <div>
                                                                    <img src={Calendar} style={{ width: "15px", marginRight: "5px" }} />
                                                                </div>
                                                                <div>{format(new Date(post.date), 'dd MMMM, yyyy', { locale: el })}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-4 md:px-6 pt-4">
                                                        <Link className="post-btn-readmore" to={`${props.pageContext.language === "EL" ? "/blog/" : "/" + props.pageContext.language.toLowerCase() + "/blog/"}${post.slug}/`}>
                                                            {props.pageContext.language === "EL" ? "Λεπτομέρειες" : "Read More"}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Link>
                                        </article>
                                    );
                                })}


                                {(!data.allWpPage.nodes[0].relatedEvents.relatedEvents) && (
                                    <div className="text-center rel-ev-editor" dangerouslySetInnerHTML={{ __html: data.allWpPage.nodes[0].content }} />
                                )}
                            </div>
                        </div>
                    </section>

                </div>
            </Layout>
        </div>
    );
}

export default EventsTemplate


export const pageQuery = graphql`query GET_LAST_EVENTS($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    content
    breadcrumbAcf {
         breadcrumbName
        
       }
       relatedEvents {
        relatedEvents {
          ... on WpPost {
            uri
            slug
            title
            date
            author {
              node {
                firstName
              }
            }
            featuredImage {
              node {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }

    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`
